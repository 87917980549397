(function ($, window, document, undefined) {

  'use strict';

  $(function () {

    var clickEventType = ((document.ontouchstart !== null) ? 'click' : 'touchstart');


    // INIT PARALLAX
    $('.bg').parallax();


    //INIT SLIDER 
    function initSlider(){

      $('.slider').slick({
        infinite: true,
        adaptiveHeight: true,
        dots: true,
        lazyLoad: 'progressive',
        prevArrow: '<div class="slick-prev slick-arrow"><svg role="img" class="prev"><use xlink:href="#arrow-left"></use></svg></div>',
        nextArrow: '<div class="slick-next slick-arrow"><svg role="img" class="next"><use xlink:href="#arrow-right"></use></svg></div>'
      })

      // PAUSE VIDEO ON INACTIVE SLIDES
      $('.slider').on('beforeChange', function(e, slick) {
        videoCommand('pauseVideo')
        $('.video-wrap').removeClass('video-play');
      });

      // HIDE VIDEO THUMBNAIL AND PLAY VIDEO
      $('.slider').on('click', '.play', function(e) {
        $(this).parent().parent().addClass('video-play');
        videoCommand('playVideo')
      });
    }

    if($('.slider .slide').length>0){
      initSlider();
    }



    //COMMANDS FOR YOUTUBE VIDEOS
    function videoCommand(command){
      var currentSlide, slideType, player, command;
      currentSlide = $('.slick-current');
      
      player = currentSlide.find('iframe').get(0);
      command = {
        "event": "command",
        "func": command
      };
      
      if(player != undefined){
        player.contentWindow.postMessage(JSON.stringify(command), "*");
      }
    }


    //PAUSE BG VIDEOS
    function pauseBgVideos(){
      $('article.portfolio video').each(function(index, el) {
        $(this).get(0).pause();
      });
    }


    //PLAY BG VIDEOS
    function playBgVideos(){
      $('article.portfolio video').each(function(index, el) {
        $(this).get(0).play();
      });
    }



    function loadPage(url,title){
      $('body').addClass('noscroll');
      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'html',
      })
      .done(function(data) {
        var html = $(data).filter('main').html();
        $('body').addClass('ajax-active');
        $('#ajax-container .inner').html(html);
        $('#ajax-container header > *,#ajax-container .slider, #ajax-container .description,#ajax-container .similar-posts .vh').addClass('show-animated');
        $('#ajax-container').attr('class','loaded');
        if($('#ajax-container .slider .slide').length>0){
          initSlider();
        }

        pauseBgVideos();

        var time = 600;

        $('#ajax-container .show-animated').each(function(index, el) {
          var $this  = $(this);
          function delayed() {
               $this.addClass('animated fadeInUp')
           }
          setTimeout( delayed , time );
          time += 100;
        });

        if($('#ajax-container .slider .slide').length>0){
          $('.slider').slick('setPosition');
        }

      })
      .fail(function() {
        //console.log("error");
      })
      .always(function(){
        //$('body').removeClass('loading')
        ga('set', { page: url, title: title });
        ga('send', 'pageview');
      })
    }


    function unloadPage(){
      $('#ajax-container').addClass('leaving');
      $('body').removeClass('noscroll');
      $('#ajax-container').removeClass('loaded');
      videoCommand('pauseVideo');
      playBgVideos();
      $('body').removeClass('ajax-active');
      setTimeout(emptyAjaxContainer,1000);

    }


    function emptyAjaxContainer(){
      $('#ajax-container .inner').html('')
    }





    // GET MORE POSTS
    $('.pagination').on('click', 'a.next', function(e) {
      $('body').addClass('loading');
      e.preventDefault();

      var url = $(this).attr("href");
      $.ajax({
        url: url,
        type: 'POST'
      })
      .done(function(data) {
        var newPosts,
            newPagination;

        newPosts = $(data).find('.portfolio-posts').html()
        newPagination = $(data).find('.pagination').html();

        $(newPosts).each(function(index, el) {
          $(this).addClass('new')
        });

        $('.pagination').html(newPagination)

        $(newPosts).appendTo('.portfolio-posts').addClass('new');
        $('.portfolio-posts .new').each(function(index, el) {
          prepareElemForRevealAnimation($(this));
          prepareElemForRevealAnimation($(this).find('.vh'));
        });

        $('article.new').first().addClass('animated fadeInUp');
        $('video','article.new').each(function(index, el) {
          var videosrc = $(this).data('src');
          $(this).html('<source src="'+videosrc+'" type="video/mp4">');
        });
        
        $('article.new').removeClass('new');



      })
      .fail(function() {
        //console.log('error');
      })
      .always(function() {
        $('body').removeClass('loading');
      });
    });






    // HISTORY STATE
    var path = window.location.pathname;
  	var location = window.history.location || window.location;
   
    $(document).on('click', 'article.portfolio > a,.logo-head,a.back', function() {
      history.pushState(null, null, this.href);
      var path = window.location.pathname;
      if(path == '/'){
        unloadPage();
      }else{
        loadPage(this.href,this.title);
      }
      
      return false;
    });

    $(window).on('popstate', function(e) {
      var path = window.location.pathname;
      var title = '';
      if(path == '/'){
        if(!$('body').hasClass('home')){           
          window.location.href = '/';
        }
        unloadPage();
      }else{
        loadPage(path,title);
      }
    });




    //SHOW NAV
    $('.logo-head').click(function(e) {
      if(!$('body').hasClass('ajax-active')){
        $('body').toggleClass('nav-toggle');
      }
    });


    //SCROLL NAV
    $('nav.main a').on(clickEventType,function(e) {
      e.stopPropagation();
      e.preventDefault();

      var page = $(this).attr('data-page');
      var offset =  $('.scrolltarget[data-page="'+page+'"]').offset().top;
      $('html, body').stop().animate({ scrollTop: offset });
      $('body').removeClass('nav-toggle');
    });


    $('.show-imprint').click(function(e) {
      e.stopPropagation();
      e.preventDefault();
      $('article.imprint').stop().slideToggle();

      var offset =  $('article.imprint').offset().top;
      $('html, body').stop().animate({ scrollTop: offset });
    });

    $('.show-legal').click(function(e) {
      e.stopPropagation();
      e.preventDefault();
      $('article.legal').stop().slideToggle();

      var offset =  $('article.legal').offset().top;
      $('html, body').stop().animate({ scrollTop: offset });
    });




    //ADD ANIMATION CLASSES WHEN ELEM IS IN VIEWPORT
    function prepareElemForRevealAnimation(elem){
      //elem.addClass('gotyou')
      var waypoint = new Waypoint({
        element: elem,
        handler: function(direction) {
          elem.addClass('animated fadeInUp');
        },
        offset: '50%'
      })
    }

    $('.vh').each(function(index, el) {
      prepareElemForRevealAnimation($(this));
    });




    //SET LOGO SIZE AND POSITION
    var currentScroll = $(document).scrollTop();
    if(currentScroll>30){
      $('.logo-head').addClass('fixed');
    }else{
      $('.logo-head').removeClass('fixed');
    }

    $(window).scroll(function(event) {
      var currentScroll = $(document).scrollTop();
      if(currentScroll>30){
        $('.logo-head').addClass('fixed');
      }else{
        $('.logo-head').removeClass('fixed');
      }
    });




    //LAZY LOAD VIDEOS
    $('.portfolio video').each(function(index, el) {
      var videosrc = $(this).data('src');
      $(this).html('<source src="'+videosrc+'" type="video/mp4">');
    });




    if($(window).width()<1000){
      $('article.portfolio:first,article.portfolio:first .vh').addClass('animated fadeInUp');
    }




    //LOGIN PAGE
    if($('#login').length){
      $('input#user_login').attr("placeholder", "Benutzer");
      $('#user_pass').attr("placeholder", "Passwort");
    }



    ///LEGAL

    $('body').on('click','.cc-link', function(e) {
     e.preventDefault();

     $('a.show-legal').trigger('click');

     return false;
    });






  });

})(jQuery, window, document);